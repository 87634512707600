import Text from '../../components/text/Text';
import H1 from '../../components/h1/H1';
import H3 from '../../components/h3/H3';
import './Portfolio.css';
import portfolioData from './portfolioData';
import { Link } from 'react-router-dom';
import SocialMediaButtons from 'react-social-media-buttons';
import '../../components/button-link/ButtonLink.css';
import ButtonLinkSecondary from '../../components/button-link-secondary/ButtonLinkSecondary';

export default function Portfolio() {
  return (
    <div>
      <div className="masthead">
        <H1>Hi 👋🏼 I'm Ramzi </H1>
        <Text center>
          I'm a full-stack, growth-mindset-focused product designer and
          strategist currently at Bubble, and previously at HubSpot. I
          have led product development teams from discovery through delivery, within the
          SMB and enterprise space, focusing on connecting the dots between business goals,
          user needs, and innovation.
        </Text>
  </div>
  </div>



  );
}
