import { createContext, useState, useEffect } from 'react';
import './App.css';
import { Switch, Route, useLocation } from 'react-router-dom';
import Navigation from './components/navigation/Navigation';
import About from './pages/about/About';
import Portfolio from './pages/portfolio/Portfolio';
import Cms from './pages/detail/Cms';
import UsCopyright from './pages/detail/UsCopyright';
import NewThink from './pages/detail/NewThink';
import HubSpot from './pages/detail/Hubs';
import Hatch from './pages/detail/Hatch';
import Bubble from './pages/detail/Bubble';
import Footer from './components/footer/Footer';
import SocialMediaButtons from 'react-social-media-buttons';


export const DarkMode = createContext(null);

// To-Do:
//  - detail page content
//  - detail image alt tags
//  - deatil image zoom functionality
//  - portfolio mobile order
//  - images (png)
//  - icons

export default function App() {
  const [darkMode, setDarkMode] = useState(false);

  
  return (
    <div className={`app-container ${darkMode ? 'dark-mode' : ''}`}>
      {/* <iframe title="figma" style={{border: '1px solid rgba(0, 0, 0, 0.1)'}} width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FhJAAT3Ioi1uajAUWTGxHXe%2FCustom-Content-Types%3Fnode-id%3D0%253A1" allowfullscreen></iframe> */}
      <DarkMode.Provider value={{ darkMode, setDarkMode }}>
        <Navigation />
        <Switch>
          <Route path="/cms">
            <Cms />
          </Route>
          <Route path="/uscopyright">
            <UsCopyright />
          </Route>
          <Route path="/newthink">
            <NewThink />
          </Route>
          <Route path="/hubspot">
            <HubSpot />
          </Route>
          <Route path="/hatch">
            <Hatch />
          </Route>
          <Route path="/bubble">
            <Bubble />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Portfolio />
          </Route>
        </Switch>
        <GoToTop />
        <Footer />
      </DarkMode.Provider>
    </div>
  );
}

function GoToTop() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return null;
}
